// ANIMATE ON SCROLL PLUGIN
;$(function() {
  // source: http://blog.webbb.be/trigger-css-animation-scroll/
  var $window           = $(window),
      win_height_padded = $window.height() * 1.1,
      isTouch           = Modernizr.touch;

  if (isTouch) { $('.revealOnScroll').addClass('animated');}

  // $window.on('scroll', revealOnScroll); original
  $(window).scroll(revealOnScroll);

  function revealOnScroll() {
    var scrolled = $window.scrollTop(),
        win_height_padded = $window.height();
        // win_height_padded = $window.height() * 1.1; original

    // Showed...
    $(".revealOnScroll:not(.animated)").each(function () {
      var $this     = $(this),
          offsetTop = $this.offset().top;

      if (scrolled + win_height_padded > offsetTop) {
        if ($this.data('timeout')) {
          window.setTimeout(function(){
            $this.addClass('animated ' + $this.data('animation'));
          }, parseInt($this.data('timeout'),10));
        } else {
          $this.addClass('animated ' + $this.data('animation'));
        }
      }
    });
    // Hidden... + COMMENTED TO STOP REPEATING THE ANIMATION
    // $(".revealOnScroll.animated").each(function (index) {
    //   var $this     = $(this),
    //       offsetTop = $this.offset().top;
    //   if (scrolled + win_height_padded < offsetTop) {
    //     // $(this).removeClass('animated fadeInUp flipInX lightSpeedIn'); original
    //     $(this).removeClass('animated ' + $this.data('animation'));
    //   }
    // });
  }

  revealOnScroll();
});