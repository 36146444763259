/* ----------------------------------- site js starts -----------------------------------  */
/* ================================= (document).ready... ================================= */
$(function () {
  // Triggers the responsive menu
  $(".menu-icon")
    .off()
    .on("click", function () {
      $("nav > ul").toggleClass("showing");
    });
  // If a link has a dropdown, add sub menu toggle.
  $(".dropdown > a").on("click", function (e) {
    $(".dropdown ul").css("display", "none");
    if ($(this).siblings("ul").css("display") == "none") {
      $(this).siblings("ul").css("display", "block");
    } else {
      $(this).siblings("ul").css("display", "none");
    }
    e.stopPropagation();
  });
  // Click anywhere on the page to hide the expanded menu
  $("html")
    .off()
    .click(function () {
      $(".dropdown > ul").css("display", "none");
    });

  /**
   * PAGE:: FORM ACTIVATION
   * @param  {event}        click event
   * @return {Boolean}      default
   */
  $("#contactform").submit(function (evt) {
    evt.preventDefault();
    submitPageData();
  });

  /**
   * FOOTER:: FORM ACTIVATION
   * @param  {event}        click event
   * @return {Boolean}      default
   */
  $("#contact_form").submit(function (evt) {
    evt.preventDefault();
    submitFooterData();
  });
});

/* ================================= page form... ================================= */

/**
 * PAGE:: MESSAGING AND NOTIFICATION
 * @return {Boolean}      false
 */
function submitPageData() {
  let cname = $("#cta-name").val(),
    cno = $("#cta-tel").val(),
    cemail = $("#cta-email").val(),
    contact_via = $("h1")
      .text()
      .replace(/(Keep.*$)/i, "");
  if (cname != "" && cno != "") {
    $.ajax({
      type: "POST",
      url: "./send_enquiry.php",
      beforeSend: function () {
        fSpinner();
        $.each($("#contactform input:not([id='btnContact'])"), function (index, val) {
          $(val).val("");
        });
      },
      complete: fSpinner,
      data: {
        cname: cname,
        cno: cno,
        cemail: cemail,
        cvia: contact_via,
      },
    })
      .done(function (response) {
        if (response == 1) {
          console.log("Success code: " + response);
          displayNotification(true);
        } else {
          console.log("DB_Error code: " + response);
          displayNotification(false);
        }
      })
      .fail(function (XMLHttpRequest) {
        console.log("Error " + XMLHttpRequest.status + ": " + XMLHttpRequest.statusText);
        displayNotification(false);
      });
  }
  return false;
}

/* ================================= footer form... ================================= */

/**
 * FOOTER:: MESSAGING AND NOTIFICATION
 * @return {Boolean}      false
 */
function submitFooterData() {
  let contact_info = $("#contact_coord").val();
  let contact_via = "FOOTER FORM";
  if (contact_info != "") {
    $.ajax({
      type: "POST",
      url: "./send_enquiry.php",
      beforeSend: function () {
        fSpinner();
        $("#contact_coord").val("");
      },
      complete: fSpinner,
      data: { cinfo: contact_info, cvia: contact_via },
    })
      .done(function (response) {
        if (response == 1) {
          console.log("Success code: " + response);
          displayNotification(true);
        } else {
          console.log("DB_Error code: " + response);
          displayNotification(false);
        }
      })
      .fail(function (XMLHttpRequest) {
        console.log("Error " + XMLHttpRequest.status + ": " + XMLHttpRequest.statusText);
        displayNotification(false);
      });
  }
  return false;
}

function displayNotification(returnedStatus) {
  $("#msgModal:visible").length ?? $("#msgModal").removeAttr("class").hide(0);
  let classList = returnedStatus ? "bgcolor-success" : "bgcolor-danger",
    showMessage = returnedStatus
      ? "Thank you for contacting brandlumin.<br />We will get back to you soon on the given channel."
      : "Err.. Problem encountered.<br /><strong>Please try again after some time.</strong>";
  $("#msgModal").addClass(classList).find("p").html(showMessage);
  $("#msgModal")
    .fadeToggle()
    .delay(5000)
    .fadeToggle(function () {
      $(this).removeAttr("class");
    });
}

function fSpinner() {
  if ($(".spinner-box").length) {
    $(".spinner-box").empty().hide().remove();
  } else {
    let spinnerBox = $("body")
        .append($("<div/>", { class: "spinner-box", id: "spinnerBox" }).hide())
        .find("#spinnerBox")
        .append($("<div/>", { class: "pulse-container", id: "pulseContainer" })),
      pulseContainer = $("#pulseContainer");
    for (var i = 1; i < 4; i++) {
      $("<div/>", { class: "pulse-bubble pulse-bubble-" + i }).appendTo(pulseContainer);
    }
    $(spinnerBox).show();
  }
}
/* ----------------------------------- site js ends -----------------------------------  */
/*! c/f to animate_on_scroll-plugin.js */
